import React from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MarketContentCard } from '@market/react-bindings';

export const PageNotFound = () => (
  <section className="tw-flex tw-justify-center tw-p-4 tw-pt-20">
    <MarketContentCard className="tw-max-w-xl tw-w-full tw-py-20 tw-px-8">
      <h2>{getIntlString('page.not-found.title')}</h2>
      <p className="tw-text-base">{getIntlString('page.not-found.body')}</p>
    </MarketContentCard>
  </section>
);
