import React, { FC } from 'react';

import { IconProps } from './type';

export const IconLoudspeaker: FC<React.PropsWithChildren<IconProps>> = ({ height = 14, width = 20, className = '' }) => (
  <div className={className}>
    <svg width={width} height={height} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 0L2 4.5V4H0V10H2V9.5L4 10V11C4 12.65 5.35 14 7 14C8.49 14 9.72 12.91 9.95 11.49L20 14V0ZM7 
          12C6.45 12 6 11.55 6 11V10.5L8 11C8 11.55 7.55 12 7 12ZM18 11.44L2 7.44V6.56L18 2.56V11.44Z"
        fill="currentColor"
      />
    </svg>
  </div>
);
