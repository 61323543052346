import React, { FC } from 'react';
import classNames from 'classnames';
import './Table.css';
import { List, CellMeasurer, CellMeasurerCache, AutoSizer, ListRowProps } from 'react-virtualized';
import { DroppableProvided, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { ArrowDown, ArrowUp } from '../icons';
import { Table } from './Table';
import { GenericTableRow } from './GenericTableRow';
import { DragDropProvider, DroppableProvider } from './DragAndDropProvider';

export type GenericTableProps = {
  getTableProps: () => any;
  getTableBodyProps: () => any;
  headerGroups: any[];
  rows: any[];
  prepareRow: (row: any) => void;
  placeholder?: string;
  rowClassName?: string;
  tableClassName?: string;
  fixedHeader?: boolean;
  contentAlign?: 'top' | 'middle';
  onDragEnd?(result: DropResult, provided: ResponderProvided): void;
  isTableVirtualized?: boolean;
  dropdownComponent?: (original: unknown) => React.ReactNode;
  checkRowIsDraggable?(original: any): boolean;
  applyClassNameToRow?(row: any): boolean;
  listRef?: React.RefObject<List>;
  modalLoaded?: boolean; // used when the table is inside a modal to set the height of the table
};

// TODO add react-table typings, possible solution https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-table
export const DEFAULT_ROW_HEIGHT = 75;
export const GenericTable: FC<React.PropsWithChildren<GenericTableProps>> = ({
  getTableProps,
  getTableBodyProps,
  rowClassName = '',
  tableClassName = '',
  headerGroups,
  rows,
  prepareRow,
  placeholder = getIntlString('table.empty.placeholder'),
  fixedHeader,
  onDragEnd,
  contentAlign,
  dropdownComponent,
  isTableVirtualized = false,
  checkRowIsDraggable,
  applyClassNameToRow,
  listRef = null,
  modalLoaded,
}) => {
  const defaultRowHeight = DEFAULT_ROW_HEIGHT;
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: defaultRowHeight,
    minHeight: defaultRowHeight,
  });
  const [height, setHeight] = React.useState(0);
  React.useEffect(() => {
    const elHeight = document.getElementById('table-body')?.clientHeight;
    if (elHeight) {
      setHeight(elHeight);
    }
  }, []);

  React.useEffect(() => {
    if (modalLoaded !== undefined && modalLoaded) {
      const elHeight = document.getElementById('table-body')?.clientHeight;
      if (elHeight) {
        setHeight(elHeight);
      }
    }
  }, [modalLoaded]);

  const appliedRowClassName = (row: any) => {
    if (applyClassNameToRow) {
      return applyClassNameToRow(row) ? rowClassName : '';
    }
    return rowClassName;
  };
  const rowRenderer = ({ index, key, parent, style }: ListRowProps) => {
    const row = rows[index];
    prepareRow(row);

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        <div key={key} style={style} className="tw-table tw-w-full tw-border-collapse">
          <GenericTableRow
            // eslint-disable-next-line react/no-children-prop
            dropdownComponent={dropdownComponent}
            row={row}
            index={index}
            onDragEnd={onDragEnd}
            isTableVirtualized={isTableVirtualized}
            rowClassName={appliedRowClassName(row)}
            contentAlign={contentAlign}
          />
        </div>
      </CellMeasurer>
    );
  };

  // eslint-ignore
  return (
    <DragDropProvider onDragEnd={onDragEnd}>
      <Table {...getTableProps()} className={tableClassName} isTableVirtualized={isTableVirtualized}>
        <Table.Head data-testid="table-head" isTableVirtualized={isTableVirtualized}>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <Table.Row isTableVirtualized={isTableVirtualized} {...headerGroup.getHeaderGroupProps()} className="tw-table-row">
              {headerGroup.headers.map((column: any) => (
                // eslint-disable-next-line react/jsx-key
                <Table.Heading
                  isTableVirtualized={isTableVirtualized}
                  className={classNames([column.className, fixedHeader ? 'tw-sticky tw-top-0 tw-bg-white tw-z-10' : '', column.canSort ? 'tw-nowrap' : ''])}
                  {...column.getHeaderProps(column.canSort && column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  {column.sorting && (
                    <span className="tw-ml-1 tw-w-1.5 tw-inline-block">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDown className="tw-h-1.5 tw-w-1.5 tw-text-blue-500" />
                        ) : (
                          <ArrowUp className="tw-h-1.5 tw-w-1.5 tw-text-blue-500" />
                        )
                      ) : (
                        column.sorting && <ArrowDown className="tw-h-1.5 tw-w-1.5" />
                      )}
                    </span>
                  )}
                </Table.Heading>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <DroppableProvider isDroppable={!!onDragEnd}>
          {(droppableProvided?: DroppableProvided) => (
            <Table.Body
              isTableVirtualized={isTableVirtualized}
              {...getTableBodyProps()}
              innerRef={droppableProvided?.innerRef as (...args: unknown[]) => unknown}
            >
              {rows.length ? (
                isTableVirtualized ? (
                  <div style={{ height: `${height}px` }} className={classNames(['tw-w-full'])}>
                    <AutoSizer className="tw-w-full tw-h-full">
                      {({ height, width }) => (
                        <List
                          ref={listRef}
                          style={{ paddingRight: '0' }}
                          rowHeight={cache.rowHeight}
                          width={width}
                          height={height}
                          rowRenderer={rowRenderer}
                          rowCount={rows.length}
                          overscanRowCount={10}
                        />
                      )}
                    </AutoSizer>
                  </div>
                ) : (
                  rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={`row-${index}`}>
                        <GenericTableRow
                          row={row}
                          index={index}
                          onDragEnd={onDragEnd}
                          isDragDisabled={checkRowIsDraggable}
                          isTableVirtualized={isTableVirtualized}
                          rowClassName={appliedRowClassName(row)}
                          contentAlign={contentAlign}
                          dropdownComponent={dropdownComponent}
                        />
                        {dropdownComponent && <>{dropdownComponent}</>}
                      </React.Fragment>
                    );
                  })
                )
              ) : (
                <Table.Row isTableVirtualized={isTableVirtualized}>
                  <Table.Data isTableVirtualized={isTableVirtualized}>
                    <div className="tw-py-1.5 tw-px-1 tw-font-normal tw-text-sm tw-text-blue-gray-400">{placeholder}</div>
                  </Table.Data>
                  {Array(headerGroups[0]?.headers?.length - 1)
                    .fill(1)
                    .map((_, index) => (
                      <Table.Data isTableVirtualized={isTableVirtualized} key={index} />
                    ))}
                </Table.Row>
              )}
              {droppableProvided?.placeholder}
            </Table.Body>
          )}
        </DroppableProvider>
      </Table>
    </DragDropProvider>
  );
};
