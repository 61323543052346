import * as React from 'react';
import { PlayCircle } from '../icons';

interface Props {
  text: string;
  url: string;
}
export const TitleHelpBlock = ({ text, url }: Props) => {
  return text ? (
    <div className="tw-mt-1 tw-flex tw-items-center">
      <PlayCircle />
      <span className="tw-ml-2">
        Need Help?{' '}
        <a href={url} target="_blank" className="tw-text-light-blue-400 hover:tw-text-blue-gray-900" rel="noreferrer">
          {text}
        </a>
      </span>
    </div>
  ) : null;
};
