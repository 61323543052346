import React from 'react';

export const PlayCircle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M7 5L13 9L7 13V5Z" fill="black" fillOpacity="0.9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M9 0C4.02994 0 0 4.02994 0 9C0 13.9701 4.02994 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02994 13.9701 0 9 0ZM2 9C2 5.13451 5.13451 2 9 2C12.8655 2 16 5.13451 16 9C16 12.8655 12.8655 16 9 16C5.13451 16 2 12.8655 2 9Z"
      fill="black"
      fillOpacity="0.9"
    />
  </svg>
);
